<template>
    <div  class="skeleton__wrapper">
        <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>
        
        <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>

         <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton__title"></div>
            <div class="skeleton-child skeleton__content"></div>
            <div class="skeleton-child skeleton__content"></div>
        </div>
    </div>
</template>


<style>
.skeleton__wrapper{
  width: 100%;
  height: 100%;
  padding:1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
.skeleton__title{
  width: 50%;
  height: 1rem;
  margin-bottom: .25rem;
}
.skeleton__content{
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
}
</style>
