<template>
  <template v-if="!isMobile && boxScoreDatalist.length > 0">
    <div class="box-score-table" v-for="(data, i) in boxScoreDatalist" :key="i">
      <div class="box-score-table__team-info" v-if="teamsInfo !== null">
        <div class="icon-league mr-1rem">
          <img class="img-contain" :src="`${s3ImgUrl}/${teamsInfo.homeLogo}`" v-if="i === 0">
          <img class="img-contain" :src="`${s3ImgUrl}/${teamsInfo.awayLogo}`" v-if="i === 1">
        </div>
        <div class="box-score-table__team-name" v-if="i === 0">{{ teamsInfo.homeTeamName }}</div>
        <div class="box-score-table__team-name" v-if="i === 1">{{ teamsInfo.awayTeamName }}</div>
      </div>
      <div class="box-score-table__team-title text-uppercase">
        <div>{{ $t("PLAYERS") }}</div>
        <div>{{ $t("POS") }}</div>
        <div>{{ $t("MIN") }}</div>
        <div>{{ $t("PTS") }}</div>
        <div>{{ $t("REB") }}</div>
        <div>{{ $t("AST") }}</div>
        <div>{{ $t("STL") }}</div>
        <div>{{ $t("BLK") }}</div>
        <div>{{ $t("FG") }}</div>
        <div>{{ $t("3PT") }}</div>
        <div>{{ $t("FT") }}</div>
        <div>{{ $t("OFF") }}</div>
        <div>{{ $t("DEF") }}</div>
        <div>{{ $t("PF") }}</div>
      </div>
      <div class="box-score-table__team-data-content" v-for="(player, j) in data.playerList" :key="j">
        <div>
          <Navigation class="box-score-table__team-member" :category="'player'" :name="player.playerName" :id="player.playerId">
            <img class="icon-player team-info__overview-goal__player-img img-contain" :src="`${s3ImgUrl}/${player.playerLogo}`">
            <span>{{ player.playerName }}</span>
          </Navigation>
        </div>
        <div>{{ player.pos }}</div>
        <div>{{ player.min }}</div>
        <div>{{ player.pts }}</div>
        <div>{{ player.reb }}</div>
        <div>{{ player.ast }}</div>
        <div>{{ player.stl }}</div>
        <div>{{ player.blk }}</div>
        <div>{{ player.fg }}</div>
        <div>{{ player.tpt }}</div>
        <div>{{ player.ft }}</div>
        <div>{{ player.off }}</div>
        <div>{{ player.def }}</div>
        <div>{{ player.pf }}</div>
      </div>
      <div class="box-score-table__team-data-total">
        <div class="text-uppercase">{{ $t("TOTAL") }}</div>
        <div>&nbsp;</div>
        <div>{{ data.total.min }}</div>
        <div>{{ data.total.pts }}</div>
        <div>{{ data.total.reb }}</div>
        <div>{{ data.total.ast }}</div>
        <div>{{ data.total.stl }}</div>
        <div>{{ data.total.blk }}</div>
        <div>{{ data.total.fg }}</div>
        <div>{{ data.total.tpt }}</div>
        <div>{{ data.total.ft }}</div>
        <div>{{ data.total.off }}</div>
        <div>{{ data.total.def }}</div>
        <div>{{ data.total.pf }}</div>
      </div>
    </div>
  </template>

  <!--  && !isSkeletonLoading.boxScore -->
  <template v-if="isMobile && boxScoreDatalist.length > 0">
    <div class="ml-1rem mr-1rem">
      <div v-if="teamsInfo !== null" class="box-score-table-tabs__container">
        <div @click="toggleBoxScoreTable(0)" :class="[{'selected': selectedBoxScoreTable === 0}, 'box-score-table-tab']">{{ teamsInfo.homeTeamName }}</div>
        <div @click="toggleBoxScoreTable(1)" :class="[{'selected': selectedBoxScoreTable === 1}, 'box-score-table-tab']">{{ teamsInfo.awayTeamName }}</div>
      </div>
     
        <div class="box-score-table" v-for="(data, i) in boxScoreDatalist" :key="i" v-show="i == selectedBoxScoreTable">
          <div class="mobile-box-score-table__left">
            <div class="mobile-box-score-table__title border">{{ $t("PLAYERS") }}</div>
            <div class="mobile-box-score-table__left-team-member-container">
              <div class="mobile-box-score-table__team-member" v-for="(player, j) in data.playerList" :key="j">
                <Navigation class="display-flex-center" :category="'player'" :name="player.playerName" :id="player.playerId">
                  <img class="icon-player team-info__overview-goal__player-img img-contain" :src="`${s3ImgUrl}/${player.playerLogo}`">
                  <span>{{ player.playerName }}</span>
                </Navigation>
              </div>
            </div>
            <div class="mobile-box-score-table__team-data-total border">{{ $t("TOTAL") }}</div>
          </div>

          <div class="mobile-box-score-table__right">
            <div class="mobile-box-score-table__right-title-container">
              <div class="mobile-box-score-table__title">{{ $t("POS") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("MIN") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("PTS") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("REB") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("AST") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("STL") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("BLK") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("FG") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("3PT") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("FT") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("OFF") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("DEF") }}</div>
              <div class="mobile-box-score-table__title">{{ $t("PF") }}</div>
            </div>
            <div class="mobile-box-score-table__right-team-member-container">
              <div class="mobile-box-score-table__right-team-member" v-for="(player, k) in data.playerList" :key="k">  
                <div>{{ player.pos }}</div>
                <div>{{ player.min }}</div>
                <div>{{ player.pts }}</div>
                <div>{{ player.reb }}</div>
                <div>{{ player.ast }}</div>
                <div>{{ player.stl }}</div>
                <div>{{ player.blk }}</div>
                <div>{{ player.fg }}</div>
                <div>{{ player.tpt }}</div>
                <div>{{ player.ft }}</div>
                <div>{{ player.off }}</div>
                <div>{{ player.def }}</div>
                <div>{{ player.pf }}</div>
              </div>
            </div>
            <div class="mobile-box-score-table__team-data-total mobile-box-score-table__right-team-data-total">
              <div>&nbsp;</div>
              <div>{{ data.total.min }}</div>
              <div>{{ data.total.pts }}</div>
              <div>{{ data.total.reb }}</div>
              <div>{{ data.total.ast }}</div>
              <div>{{ data.total.stl }}</div>
              <div>{{ data.total.blk }}</div>
              <div>{{ data.total.fg }}</div>
              <div>{{ data.total.tpt }}</div>
              <div>{{ data.total.ft }}</div>
              <div>{{ data.total.off }}</div>
              <div>{{ data.total.def }}</div>
              <div>{{ data.total.pf }}</div>
            </div>
          </div>
        </div>
    </div>
  </template>

  <div class="no-data w-100" v-show="boxScoreDatalist.length == 0">{{$t('NO_BOX_SCORE')}}</div>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.boxScore"></GeneralContentSkeleton> -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import config from '@/js/config.js'
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: {
    GeneralContentSkeleton,
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.boxScoreDatalist = [];
    this.teamsInfo = null;
  },
  watch: {
    matchDetailScore: {
      handler(newVal, oldVal) {
        if (newVal) 
          this.teamsInfo = newVal;
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) this.getBasketballBoxScoreData();
      }
    }
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "matchDetailScore",
      "manualRefreshMatchDetail",
    ]),
  },
  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      matchId: "", 
      boxScoreDatalist: [],
      teamsInfo: null,
      selectedBoxScoreTable: 0,
      isSkeletonLoading: {
        boxScore: false,
      },
    };
  },
  methods: {
    ...mapActions([
      "getBasketballBoxScore",
    ]),
    ...mapMutations([
      "MANUAL_REFRESH_MATCH_DETAIL",
    ]),
    init() {
      if (Object.keys(this.matchDetailScore).length > 0) {
        this.teamsInfo = this.matchDetailScore;
      }
      this.getBasketballBoxScoreData();
    },
    async getBasketballBoxScoreData() {
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.boxScore = true;
      }

      const result = await this.getBasketballBoxScore({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.boxScore = false;
        this.verifyIfAllAPICallsDone();
      }

      if (result.message === "Success" && result.result != null) {
        let result01 = result.result;
        this.boxScoreDatalist = [
          {
            playerList: result01.homePlayerList,
            total: result01.homeTotal
          },
          {
            playerList: result01.awayPlayerList,
            total: result01.awayTotal
          }
        ];
      }
    },
    toggleBoxScoreTable(i) {
      this.selectedBoxScoreTable = i;
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.boxScore) {
        this.MANUAL_REFRESH_MATCH_DETAIL();
      }
    }
  },
}
</script>

<style scoped>
.box-score-table {
  width: 80%;
  margin: 1.5rem auto;
}
.box-score-table__team-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.box-score-table__team-name {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.box-score-table__team-title,
.box-score-table__team-data-content,
.box-score-table__team-data-total {
  display: flex;
  align-items: center;
  width: 100%;
}
.box-score-table__team-title div,
.box-score-table__team-data-content div,
.box-score-table__team-data-total div {
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 0.688rem;
  display: flex;
  justify-content: center;
  flex: 1 0 3.5rem;
}
.box-score-table__team-title div:first-child,
.box-score-table__team-data-content div:first-child,
.box-score-table__team-data-total div:first-child {
  justify-content: flex-start;
  flex: 1 0 13.125rem;
}
.box-score-table__team-title div:first-child,
.box-score-table__team-data-content div:first-child {
  border-right: 1px solid var(--color-grey-40);
}
.box-score-table__team-data-total div:first-child {
  border-right: 1px solid #20285A;
}
.box-score-table__team-member {
  display: flex;
  align-items: center;
  color: var(--color-grey-light);
}
.team-info__overview-goal__player-img {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem
}
.box-score-table__team-title {
  text-transform: uppercase;
  font-weight: 700;
  background: var(--color-grey-60);
}
.box-score-table__team-data-content:nth-child(odd) {
  background: var(--color-grey-20);
}
.box-score-table__team-data-content:nth-child(even) {
  background: var(--color-grey-60);
}
.box-score-table__team-data-total {
  text-transform: uppercase;
  font-weight: 700;
  background: #0A4785;
}

@media (max-width: 768px) {
  .box-score-table-tabs__container {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  .box-score-table-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
  }
  .box-score-table-tab:nth-child(1) {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .box-score-table-tab:nth-child(2) {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .box-score-table-tab.selected {
    color: #000000;
    background: var(--bg-selected);
    border-color: var(--bg-selected);
  }
  .box-score-table {
    display: flex;
    width: 100%;
  }
  .mobile-box-score-table__left > div {
    min-width: 7.5rem;
    max-width: 7.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  .mobile-box-score-table__right {
    overflow-x: scroll;
  }
  .box-score-table__team-title {
    display: flex;
    flex-direction: column;
  }
  .box-score-table__team-title {
    align-items: unset;
  }
  .mobile-box-score-table__title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.688rem;
    background: var(--color-grey-60);
  }
  .mobile-box-score-table__title.border {
    border-right: 1px solid var(--color-grey-40);
  }
  .mobile-box-score-table__left-team-member-container {
    padding: 0 !important;
    border-right: 1px solid var(--color-grey-40);
  }
  .mobile-box-score-table__team-member {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 0.688rem;
    color: var(--color-grey-light);
    height: 3rem;
    max-height: 3rem;
  }
  
  .mobile-box-score-table__team-member:nth-child(odd),
  .mobile-box-score-table__right-team-member:nth-child(odd) > div {
    background: var(--color-grey-20);
  }
  .mobile-box-score-table__team-member:nth-child(even),
  .mobile-box-score-table__right-team-member:nth-child(even) > div {
    background: var(--color-grey-60);
  }
  .mobile-box-score-table__team-data-total,
  .mobile-box-score-table__team-data-total > div {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.688rem;
    background: #0A4785;
  }
  .mobile-box-score-table__team-data-total.border {
    border-right: 1px solid #20285A;
  }
  .mobile-box-score-table__right-title-container > div,
  .mobile-box-score-table__right-team-member > div,
  .mobile-box-score-table__right-team-data-total > div {
    min-width: 3.5rem;
    max-width: 3.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-box-score-table__right-team-member > div {
    font-weight: 400;
    font-size: 0.688rem;
    height: 3rem;
    max-height: 3rem;
  }
  .mobile-box-score-table__right-title-container,
  .mobile-box-score-table__right-team-member,
  .mobile-box-score-table__right-team-data-total  {
    display: flex;
  }
}
</style>
